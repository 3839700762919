
@import @sassConfig

.custom-notification
	border-radius: 8px !important
	font: 16px $font !important

	&.Toastify__toast-theme--dark
		--toastify-color-dark: #0E2235

.Toastify__toast-icon
	color: var(--icon-color)

.Toastify__toast--default
	--toastify-color-progress-dark: #8d8d8d
	&.Toastify__toast-theme--dark
		--icon-color: var(--toastify-text-color-dark)

.Toastify__toast--info
	--icon-color: var(--toastify-icon-color-info)

.Toastify__toast--success
	&.Toastify__toast-theme--dark
		--toastify-color-success: #86BB41
		--toastify-icon-color-success: var(--toastify-color-success)
		--toastify-color-progress-success: var(--toastify-color-success)
	--icon-color: var(--toastify-icon-color-success)

.Toastify__toast--warning
	&.Toastify__toast-theme--dark
		--toastify-color-warning: #FFD260
		--toastify-icon-color-warning: var(--toastify-color-warning)
		--toastify-color-progress-warning: var(--toastify-color-warning)
	--icon-color: var(--toastify-icon-color-warning)

.Toastify__toast--error
	&.Toastify__toast-theme--dark
		--toastify-color-error: #FF6060
		--toastify-icon-color-error: var(--toastify-color-error)
		--toastify-color-progress-error: var(--toastify-color-error)
	--icon-color: var(--toastify-icon-color-error)
