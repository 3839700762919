@import '../config'

.header
	display: flex
	justify-content: space-between
	padding: 0 32px
	box-shadow: $box-shadow

	&__wrapper
		display: flex
		align-items: center

		&-logo
			margin-right: 50px
			@include max-width(1500)
				margin: 0
				flex: auto

		&-menu
			flex: 1 1 auto

		&:last-child
			justify-content: flex-end

	&__title
		max-width: 253px
		width: 100%
		margin-left: 20px
		padding: 0 20px
		border-left: 1px solid $medium-gray
		@include max-width(1500)
			margin: 0 10px
			width: 200px
		@include max-width(1280)
			margin: 0
			width: 195px

		&-text
			font: normal 16px/125% $font
			color: $black
			@include max-width(1500)
				font: normal 14px/125% $font

	&__search
		position: relative
		max-width: 260px
		width: 100%
		height: 40px
		margin-right: 28px
		display: flex

		&-input
			width: 100%
			height: 100%
			background: #FCFCFD
			border: 1px solid $gray
			border-radius: 8px
			padding: 0 8px 0 12px
			color: $text-gray
			font: normal 14px/150% $font

		&-button
			position: absolute
			padding: 0
			border: none
			outline: none
			background: transparent
			cursor: pointer
			top: 8px
			right: 8px

	&__bell
		margin-right: 28px
		display: block
		cursor: pointer
		transition: opacity .3s
		height: 24px
		min-width: 24px

		&:hover
			opacity: .8

	&__user
		display: flex
		margin-right: 20px
		align-items: center
		@include max-width(1500)
			margin-right: 15px

		&-icon
			background: $dark-gray
			border-radius: 12px
			margin-right: 16px
			width: 40px
			height: 40px
			display: flex
			align-items: center
			justify-content: center
			position: relative

			img
				border-radius: 12px

				width: 24px
				height: 24px

		&-info
			display: flex
			flex-direction: column

		&-label
			color: #737B8F
			font: normal 11px/125% $font
			margin-bottom: 4px
			width: 150px
			overflow: hidden
			text-overflow: ellipsis

		&-name
			color: $blue
			font: normal 14px/125% $font
			white-space: nowrap
			max-width: 150px
			text-overflow: ellipsis
			overflow: hidden

	&__logout
		border: none
		background: transparent
		outline: none
		padding: 0
		cursor: pointer
		height: 24px
		min-width: 24px

	&__list
		display: flex
		align-items: center
		justify-content: flex-start
		gap: 60px
		@include max-width(1500)
			gap: 50px
		padding: 0 25px
		@include max-width(1280)
			gap: 20px
		@include max-width(1040)
			gap: 10px

	&__item
		height: 80px
		display: flex
		align-items: center

		&-inner
			font: 400 14px $font
			line-height: 125%
			color: $gray2
			display: flex
			height: 100%
			align-items: center
			text-align: center
			word-break: auto-phrase
			@include max-width(1280)
				font: 400 13px $font

			&-active
				color: $dark-blue
				position: relative

				&::before
					position: absolute
					content: ''
					bottom: -2px
					background-color: $blue
					width: 100%
					height: 2px
					border-radius: 2px

