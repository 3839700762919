*,
*:before,
*:after
    box-sizing: inherit


html
    box-sizing: border-box
    height: 100%


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
    html
        display: flex
        flex-direction: column


body
    height: 100%
    min-height: 100%
    margin: 0
    background-color: #fff
    line-height: normal
    text-rendering: optimizeLegibility
    text-decoration-skip: objects
    -moz-text-size-adjust: auto
    -webkit-text-size-adjust: auto
    -ms-text-size-adjust: auto
    text-size-adjust: auto
    -webkit-font-smoothing: antialiased
    -webkit-tap-highlight-color: transparent


*:focus
    outline: none


p,
dd,
dl,
figure,
blockquote
    margin: 0


blockquote,
q
    quotes: none


ul,
ol
    padding: 0
    margin: 0
    list-style-type: none


table
    border-collapse: collapse
    border-spacing: 0


th
    font-weight: inherit


h1,
h2,
h3,
h4,
h5,
h6
    margin: 0
    font-size: inherit
    font-weight: inherit
    word-break: break-word


audio,
video
    display: block


img
    display: block
    border: none


iframe
    border: none


pre,
code,
kbd,
samp
    font-family: monospace, monospace
    font-size: inherit


a
    background-color: transparent
    text-decoration: none
    color: inherit


abbr
    border: none
    text-decoration: none


b,
strong
    font-weight: inherit



dfn
    font-style: inherit


mark
    background-color: transparent
    color: inherit


small
    font-size: inherit


sub,
sup
    position: relative
    vertical-align: baseline
    font-size: inherit
    line-height: 0


sub
    bottom: -.25em


sup
    top: -.5em


button,
input,
optgroup,
select,
textarea
    padding: 0
    margin: 0
    border-radius: 0
    box-shadow: none
    background-color: transparent
    font: inherit
    color: inherit
    letter-spacing: inherit


button,
input
    overflow: visible


button,
select
    text-align: left
    text-transform: none


button,
[type='button'],
[type='reset'],
[type='submit']
    cursor: pointer
    -webkit-appearance: none


textarea
    resize: none
    overflow-y: auto
    overflow-x: hidden


button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner
    border: none
    padding: 0


button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring
    outline: none


[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button
    height: auto


[type='search']
    outline: none


[type='search']::-webkit-search-decoration
    -webkit-appearance: none


::-webkit-file-upload-button
    -webkit-appearance: none
    font: inherit


fieldset
    padding: 0
    margin: 0
    border: none


legend
    display: block
    padding: 0
    white-space: normal


select
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none


select::-ms-expand
    display: none


::-webkit-input-placeholder
    color: inherit
    opacity: 1
    transition: opacity .3s


input::-moz-input-placeholder
    color: inherit
    opacity: 1
    transition: opacity .3s


input:-moz-placeholder
    color: inherit
    opacity: 1
    transition: opacity .3s


input:-ms-input-placeholder
    color: inherit
    opacity: 1
    transition: opacity .3s


input:focus::-webkit-input-placeholder
    opacity: 0

input:focus::-moz-input-placeholder
    opacity: 0


input:focus:-moz-placeholder
    opacity: 0


input:focus:-ms-input-placeholder
    opacity: 0


svg
    display: block
    max-width: 100%


[hidden]
    display: none


.disabled,
::disabled
    cursor: not-allowed


::-ms-clear
    display: none


input:-webkit-autofill
    box-shadow: 0 0 100px #fff inset
    -webkit-text-fill-color: currentColor


::selection
    color: #fff
    background-color: #004fe4


.clearfix:after
    content: ''
    display: block
    clear: both


.visually-hidden
    position: absolute
    z-index: -1
    width: 0
    height: 0
    padding: 0
    margin: 0
    border: none
    overflow: hidden


.cover-pic,
.contain-pic
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%


.cover-pic
    object-fit: cover


.contain-pic
    object-fit: contain


.footer-page
    margin-top: auto
