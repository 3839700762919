$max-width: 1980px
$min-width: 320px

// Экраны
// ======
$desktop-lg: 1399
$desktop: 1199
$tablet: 991
$mobile: 767
$mobile-sm: 575

$font: 'PF BeauSans Pro'

// Основные размеры текста
@mixin font-header1()
  font: 400 36px $font
  line-height: 125%

@mixin font-header4()
  font: 400 24px $font
  line-height: 125%

@mixin font-header5()
  font: 400 20px $font
  line-height: 125%

@mixin font-paragraph-big()
  font: 400 18px $font
  line-height: 150%

@mixin font-paragraph()
  font: 400 16px $font
  line-height: 150%

@mixin font-description-table()
  font: 400 12px $font
  line-height: 125%

@mixin font-accent()
  font: 400 18px $font
  line-height: 150%

@mixin font-description()
  font: 400 14px $font
  line-height: 150%

@mixin font-info()
  +font-description
  line-height: 100%

@mixin font-tab()
  font: 400 16px $font
  line-height: 125%

// default inputs
@mixin input-title()
  font: 400 24px $font
  line-height: 125%
  color: $dark-blue

@mixin input-subTitle()
  font: 600 16px $font
  line-height: 125%
  color: $black

@mixin input-label()
  font: 400 14px $font
  line-height: 129%

@mixin input-field()
  font: 400 16px $font
  color: $dark-blue
  line-height: 125%

@mixin input-placeholder()
  font: 400 16px $font
  color: $gray3
  line-height: 125%

@mixin input-desc()
  font: 400 12px $font
  color: $gray2
  line-height: 150%

@mixin input-subDesc()
  font: 400 12px $font
  color: $gray2
  line-height: 150%

@mixin input-error()
  font: 400 11px $font
  line-height: 125%
  color: $error

// custom fields form
@mixin fields-title()
  font: 400 24px $font
  line-height: 125%
  color: $dark-blue

@mixin fields-label()
  font: 600 16px $font

@mixin fields-desc()
  font: 400 14px $font
  color: $gray2
  line-height: 150%

@mixin fields-subDesc()
  font: 400 14px $font
  color: $gray2
  line-height: 150%

@mixin fields-error()
  font: 400 12px $font

// Цвета
$color: #012D55
$white: #FFFFFF
$black: #000000
$light-gray: #FCFCFD
$gray: #EAECF3
$dark-gray: #F6F7FC
$medium-gray: #F5F7FC
$text-gray: #B6BCC9
$input-focused-border: #98A3B4
$input-error-border: #FFB7C1

$error: #EC787D

// Цвета из UI кита

$dark: #0E2235
$dark-blue: #012D55
$blue: #0075DC
$gray2: #737B8F
$gray3: #B6BCC9
$gray4: #E4E8F0
$gray5: #F5F7FC
$gray6: #FCFCFD
$gray7: #D9DDE4
$gray8: #F0F2FB
$bg: #F6F7FC
$red: #FF6060
$alert-red: #F93333
$light-blue: #A5D1DF
$aqua-blue: #0995E3
$green: #99D24F
$orange: #FFD260
$gray-toggle: #DDE0E8

// Box-Shadow's

$box-shadow: 0px 4px 16px rgba(0, 90, 177, 0.1)

@mixin clearfix
  &:after
    content: ''
    display: table
    clear: both

@mixin grad($color1,$color2)
  background: $color2
  background: linear-gradient(top, $color1, $color2)

@mixin rotate($rotate)
  transform: rotate($rotate+deg)

@mixin max-width($width)
  @media only screen and (max-width: #{$width}px)
    @content

@mixin min-width($width)
  @media only screen and (min-width: #{$width}px)
    @content

@mixin min-max-width($minWidth, $maxWidth)
  @media only screen and (min-width: #{$minWidth}px) and (max-width: #{$maxWidth}px)
    @content

@mixin max-height($height)
  @media only screen and (max-height: #{$height}px)
    @content

@mixin min-height($height)
  @media only screen and (min-height: #{$height}px)
    @content

// Основные медиазапросы
// =====================
// desktop >= 992px
@mixin desktop()
  +min-width($tablet)
    @content

// mobile <= 767px
@mixin desktop-lg-min()
  +max-width($desktop-lg)
    @content
// desktop-lg >= 1400px
@mixin desktop-lg()
  +min-width($desktop-lg)
    @content

// 1200px <= desktop-md <= 1399px
@mixin desktop-md()
  +min-max-width($desktop, $desktop-lg)
    @content

// 992px <= desktop-sm <= 1199px
@mixin desktop-sm()
  +min-max-width($tablet, $desktop)
    @content

// 768px <= tablet <= 991px
@mixin tablet()
  +min-max-width($mobile, $tablet)
    @content

// mobile <= 767px
@mixin mobile()
  +max-width($mobile)
    @content

// 576px <= mobile-lg <= 767px
@mixin mobile-lg()
  +min-max-width($mobile-sm, $mobile)
    @content

// mobile-sm <= 575px
@mixin mobile-sm()
  +max-width($mobile-sm)
    @content

// desktop-tablet >= 768px
@mixin desktop-tablet()
  +min-width($mobile)
    @content

// tablet-mobile <= 991px
@mixin tablet-mobile()
  +max-width($tablet)
    @content

@mixin full-wrapper()
  margin: 0 auto
  max-width: $max-width
  min-width: $min-width
  padding: 0 80px
  +desktop-lg
    padding: 0 64px
  +desktop()
    padding: 0 32px
  +tablet-mobile()
    padding: 0 16px

@mixin width($width)
  max-width: $width
  width: $width

@mixin placeholder()
  &::-webkit-input-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content


@mixin default-scrollbar()
  overflow-y: auto
  overflow-x: hidden

  &::-webkit-scrollbar
    width: 4px
    background: transparent

  &::-webkit-scrollbar-track
    background: $gray5
    border: 4px solid rgba(0, 0, 0, 0)
    border-radius: 20px

  &::-webkit-scrollbar-thumb
    border: 4px solid rgba(0, 0, 0, 0)
    border-radius: 20px
    background-color: $gray4

@mixin scrollbar()
  overflow: overlay

  &::-webkit-scrollbar
    width: 20px
    background: transparent

  &::-webkit-scrollbar-track
    background: $gray5
    border: 8px solid rgba(0, 0, 0, 0)
    background-clip: padding-box
    border-radius: 20px

  &::-webkit-scrollbar-thumb
    border: 8px solid rgba(0, 0, 0, 0)
    background-clip: padding-box
    border-radius: 20px
    background-color: $gray4

/* || Table's Cells */
@mixin cell-table-main()
  font: 400 13px $font
  line-height: 125%
  @include max-width(1280)
    font: 400 12px $font

@mixin cell-table-second()
  font: 400 12px $font
  line-height: 125%
  @include max-width(1280)
    font: 400 11px $font

@mixin cell-table-default()
  +cell-table-main
  color: $dark-blue

@mixin cell_table-header()
  +cell-table-second
  color: $gray2

@mixin cell_table-link()
  +cell-table-main
  color: $blue

@mixin cell-table-status()
  +cell-table-main
  color: $dark-blue

@mixin cell-table-person()
  +cell-table-second

@mixin cell-table-person-name()
  +cell-table-main
  color: $blue

@mixin cell-table-person-contacts()
  +cell-table-second
  color: $gray2

@mixin cell-table-default-status()
  font: 400 16px $font
  color: $dark-blue
  line-height: 125%

@mixin cell-table-protocol()
  +cell-table-main
  color: $dark-blue

@mixin cell-table-bluCell()
  color: $blue
  font: 400 20px $font
  line-height: 125%
  @include max-width(1280)
    font: 400 16px $font


@keyframes spin
  from
    transform: rotate(0deg)

  to
    transform: rotate(360deg)
