@import @sassConfig

.select
  [class*="input"], [class*="singleValue"]
    margin: 0
    padding: 0
    font: 400 16px $font
    color: $dark-blue
    line-height: 125%

  .select-filters__value-container
    padding: 0

  [class*="indicatorContainer"]
    padding: 0 0 0 10px

  [class*="indicatorSeparator"]
    display: none

  [class*="control"]
    background: $gray6
    border: 1px solid $gray
    border-radius: 8px
    padding: 10px 12px
    transition: color 0.3s, background-color 0.3s, border 0.3s
    box-shadow: none !important
    min-height: 42px

    &:hover, &:focus, &:valid
      border-color: $input-focused-border

  [class*="placeholder"]
    font: 400 16px $font
    color: $gray3
    line-height: 125%
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    display: inline-block

.select-filters__menu-portal
  .select-filters__menu
    max-width: 310px
    max-height: 320px
    background: $white
    box-shadow: 0px 4px 16px rgba(0, 70, 138, 0.2)
    border-radius: 8px
    padding: 10px 0
    z-index: 1000

  .select-filters__value-container
    padding: 0

  .select-filters__menu-list
    padding: 0
    border-radius: 0
    overflow: overlay

    &::-webkit-scrollbar
      width: 20px
      background: transparent

    &::-webkit-scrollbar-track
      background: $gray5
      border: 8px solid rgba(0, 0, 0, 0)
      background-clip: padding-box
      border-radius: 20px

    &::-webkit-scrollbar-thumb
      border: 8px solid rgba(0, 0, 0, 0)
      background-clip: padding-box
      border-radius: 20px
      background-color: $gray4

  .select-filters__option
    padding: 4px 20px
    font: 400 14px $font
    color: $dark-blue
    line-height: 150%
    transition: color 0.3s
    position: relative
    background: none

    &::before
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      background: $white
      content: ''
      transition: background-color 0.3s, z-index 0.3s
      z-index: -2

    &--is-disabled
      background: #cad2d9 !important
      color: #95A9B9 !important

  .select-filters__option--is-selected, .select-filters__option--is-focused
    color: $white

    &::before
      background: #0075DC
      z-index: -1

  .select-filters__menu-notice
    text-align: left
    font: 400 14px $font
    color: $gray3
    padding: 6px 20px

.select-filters__control--is-disabled
  background-color: $gray5 !important

.select-filters__single-value--is-disabled
  color: $gray3 !important


