/**
 * В этом файле описываем все что относится к каркасу
 */
@import "./config"

*
    outline: none
    line-height: inherit
    text-align: inherit
    vertical-align: inherit

*, *::before, *::after
    box-sizing: border-box

// Для блока "Контент"
// ===================
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, main, menu, nav, output, ruby, section, summary, time, mark, audio, video
    line-height: inherit
    text-align: inherit
    vertical-align: inherit

html
    box-sizing: border-box
    height: 100%
    width: 100%
    -ms-text-size-adjust: 100%
    -webkit-text-size-adjust: 100%

body
    display: block
    height: 100%
    width: 100%
    min-width: 288px
    position: relative
    padding: 0
    margin: 0
    text-rendering: optimizeSpeed
    scroll-behavior: smooth
    overflow: hidden
    -webkit-font-smoothing: antialiased

// ================
body > div#__next
    margin: 0 auto
    width: 100%
    position: relative
    display: flex
    flex-direction: column
    min-height: 100%
    overflow: auto
    height: 100%

// Описание базовых стилей для элементов
// =====================================
a, input, button, textarea, *:before, *:after, *:hover, *:active, *:focus
    outline: none

input, textarea
    -webkit-user-select: text

a
    transition: color .3s ease, opacity .3s ease, border .3s ease, background .3s ease
    color: $blue
    word-break: break-word

    &:hover
        color: $dark-blue

p
    margin: 0 0 12px 0

.hidden
    display: none

img
    max-width: 100%
    vertical-align: bottom

.center
    text-align: center

p, li
    a
        transition: color .3s ease

b
    font-weight: bold

.hide
    display: none

.show
    display: block

.full
    width: 100%

// WRAPPERS
// ========
.full-wrapper
    +full-wrapper
    width: 100%

.content
    width: 100%
    flex: 1 1 auto
    display: flex
    flex-direction: column
    background: $dark-gray

    +tablet
        padding-top: 60px

.link
    color: $blue

.ProseMirror
    min-height: 15rem
