@font-face
	font-family: 'PF BeauSans Pro'
	src: url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Light.eot')
	src: local('PFBeauSansPro-Light'), url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Light.eot?#iefix') format('embedded-opentype'), url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Light.woff') format('woff'), url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Light.ttf') format('truetype')
	font-weight: 300
	font-style: normal


@font-face
	font-family: 'PF BeauSans Pro'
	src: url('/fonts/FontPFBeauSansPro/PFBeauSansPro-LightItalic.eot')
	src: local('PFBeauSansPro-LightItalic'), url('/fonts/FontPFBeauSansPro/PFBeauSansPro-LightItalic.eot?#iefix') format('embedded-opentype'), url('/fonts/FontPFBeauSansPro/PFBeauSansPro-LightItalic.woff') format('woff'), url('/fonts/FontPFBeauSansPro/PFBeauSansPro-LightItalic.ttf') format('truetype')
	font-weight: 300
	font-style: italic


@font-face
	font-family: 'PF BeauSans Pro'
	src: url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Bold.eot')
	src: local('PFBeauSansPro-Bold'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Bold.eot?#iefix') format('embedded-opentype'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Bold.woff') format('woff'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Bold.ttf') format('truetype')
	font-weight: bold
	font-style: normal


@font-face
	font-family: 'PF BeauSans Pro'
	src: url('/fonts/FontPFBeauSansPro/PFBeauSansPro-BoldItalic.eot')
	src: local('PFBeauSansPro-BoldItalic'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-BoldItalic.woff') format('woff'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-BoldItalic.ttf') format('truetype')
	font-weight: bold
	font-style: italic


@font-face
	font-family: 'PF BeauSans Pro'
	src: url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Regular.eot')
	src: local('PFBeauSansPro-Regular'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Regular.eot?#iefix') format('embedded-opentype'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Regular.woff') format('woff'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Regular.ttf') format('truetype')
	font-weight: normal
	font-style: normal


@font-face
	font-family: 'PF BeauSans Pro'
	src: url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Italic.eot')
	src: local('PFBeauSansPro-Italic'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Italic.eot?#iefix') format('embedded-opentype'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Italic.woff') format('woff'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-Italic.ttf') format('truetype')
	font-weight: normal
	font-style: italic


@font-face
	font-family: 'PF BeauSans Pro'
	src: url('/fonts/FontPFBeauSansPro/PFBeauSansPro-SemiBold.eot')
	src: local('PFBeauSansPro-SemiBold'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-SemiBold.woff') format('woff'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-SemiBold.ttf') format('truetype')
	font-weight: 600
	font-style: normal


@font-face
	font-family: 'PF BeauSans Pro'
	src: url('/fonts/FontPFBeauSansPro/PFBeauSansPro-SemiBoldItalic.eot')
	src: local('PFBeauSansPro-SemiBoldItalic'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-SemiBoldItalic.woff') format('woff'),	url('/fonts/FontPFBeauSansPro/PFBeauSansPro-SemiBoldItalic.ttf') format('truetype')
	font-weight: 600
	font-style: italic
