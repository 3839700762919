@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-track {
	border-radius: 0;
}
::-webkit-scrollbar-thumb {
	@apply transition-colors bg-brand-gray-3;
	border-radius: 4px;
	border: 0;
}
