@import @sassConfig
.wrapper
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  z-index: 1000

.loader
  display: inline-block
  position: relative
  width: 64px
  height: 64px
  background: url("/img/png/loader.png")
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite


@keyframes lds-ring
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)
